import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BlogCard from "../components/blogCard";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import LogoSchema from "../components/Schema/logo";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import Layout from "../components/layout";
import AreaMap from "../components/areaMap";
import { checkPropertiesForNull } from "../utils/object";
import { SafeHtmlParser } from "../components/safe-html-parser";

const Resources = () => {
	const data = useStaticQuery(
		graphql`
			{
				site {
					siteMetadata {
						siteUrl
					}
				}
				pageData: wpPage(slug: { eq: "resources" }) {
					seoFieldGroups {
						localBusinessSchema
						metaDescription
						metaTitle
						openGraphDescription
						openGraphTitle
						productSchema
						image {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
					resourcesPageFieldsGroup {
						resourcesBannerSection {
							description
							heading
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						resourceListHeading
						resourcesMapSection {
							googleMap
							heading
						}
					}
				}
				blogPosts: allWpPost(sort: { fields: dateGmt, order: DESC }) {
					nodes {
						id
						title
						slug
						uri
						excerpt
						blogFields {
							featuredImage {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											quality: 70
											placeholder: BLURRED
											layout: FULL_WIDTH
										)
									}
								}
							}
						}

						featuredImage {
							node {
								mediaItemUrl
								altText
							}
						}
					}
				}
			}
		`
	);

	const {
		pageData: { seoFieldGroups, resourcesPageFieldsGroup },
		site,
	} = data;

	const { resourcesBannerSection, resourceListHeading, resourcesMapSection } =
		resourcesPageFieldsGroup;

	const siteUrl = site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Seymour Locksmiths",
				item: {
					url: "https://www.seymour-locksmiths.co.uk",
					id: "https://www.seymour-locksmiths.co.uk",
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Locksmith Resources",
				item: {
					url: "https://www.seymour-locksmiths.co.uk/resources",
					id: "https://www.seymour-locksmiths.co.uk/resources",
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LogoSchema /> */}
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/resources`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@jefflocksmith",
					site: "@jefflocksmith",
					cardType: "summary",
				}}
			/>

			{resourcesBannerSection &&
				!checkPropertiesForNull(resourcesBannerSection, ["heading"]) && (
					<BgImage
						image={getImage(
							resourcesBannerSection?.backgroundImage.localFile.childImageSharp
								.gatsbyImageData
						)}
						className="hero py-5 mb-3"
					>
						<Container fluid={true} className="vh-50">
							<Row className="h-100 justify-content-center align-items-center">
								<Col className="text-offwhite text-center" xs={11} sm={10}>
									<h1 className="lato fw-700 text-5xl mb-4">
										{resourcesBannerSection?.heading}
									</h1>
									<div className="text-lg">
										{resourcesBannerSection?.description && (
											<SafeHtmlParser
												htmlContent={resourcesBannerSection?.description}
											/>
										)}
									</div>
								</Col>
							</Row>
						</Container>
						<span className="hero-overlay" />
					</BgImage>
				)}

			<Container fluid={true} as="section" className="my-24">
				<Row className="mb-20">
					<Col className="d-flex justify-content-center" xs={12}>
						<h3 className="text-3xl mb-3 fw-700 lato">
							{resourceListHeading ?? "Our Blog Articles"}
						</h3>
					</Col>
					<Col className="d-flex justify-content-center" xs={12}>
						<span className="sl-underline d-block" />
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col xs={11} sm={10} xl={8}>
						<Row className="">
							{data.blogPosts.nodes.map((post) => (
								<BlogCard
									slug={post.slug}
									key={post.id}
									title={post.title}
									uri={post.uri}
									image={
										post.blogFields.featuredImage.localFile.childImageSharp
											.gatsbyImageData
									}
									excerpt={post.excerpt}
									imageAlt={post.blogFields.featuredImage.altText}
								></BlogCard>
							))}
						</Row>
					</Col>
				</Row>
			</Container>
			{resourcesMapSection &&
				!checkPropertiesForNull(resourcesMapSection, ["heading"]) && (
					<AreaMap
						heading={resourcesMapSection?.heading}
						map={resourcesMapSection?.googleMap}
					/>
				)}
		</Layout>
	);
};

export default Resources;
